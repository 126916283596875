import { render, staticRenderFns } from "./AppHeaderLogout.vue?vue&type=template&id=2b40392b"
import script from "./AppHeaderLogout.vue?vue&type=script&lang=ts"
export * from "./AppHeaderLogout.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBtn: require('/builds/project/mashuk/frontend/components/app/AppBtn.vue').default})
